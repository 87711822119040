import { render, staticRenderFns } from "./applyReleaseDetail.vue?vue&type=template&id=864b6320&scoped=true&"
import script from "./applyReleaseDetail.vue?vue&type=script&lang=js&"
export * from "./applyReleaseDetail.vue?vue&type=script&lang=js&"
import style0 from "./applyReleaseDetail.vue?vue&type=style&index=0&id=864b6320&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "864b6320",
  null
  
)

export default component.exports